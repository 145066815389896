import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Social = () => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          author {
            email
            linkedin
            dribbble
            instagram
          }
        }
      }
    }
  `)
  const author = data.site.siteMetadata.author;

  return (
    <p>
      <a href={"mailto:" + author.email} className="barelink" target="blank">Email</a> &nbsp;
      <a href={"https://linkedin.com/in/" + author.linkedin} className="barelink" target="blank">Linkedin</a> &nbsp;
      <a href={"https://dribbble.com/" + author.dribbble} className="barelink" target="blank">Dribbble</a> &nbsp;
      <a href={"https://www.instagram.com/" + author.instagram} className="barelink" target="blank">Instagram</a>
    </p>
  )
}

export default Social
